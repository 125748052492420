import React, {useState} from "react"
import {WrappedMap, MarkerWindow, GreetingWindow, PayWindow, WaitWindow, Form, ConfirmWindow, CancelWindow} from "../components"
import {createBrowserHistory} from "history"
import {BrowserRouter as Router, Route} from "react-router-dom"

import "./App.sass"

const history = createBrowserHistory()

const App = () => {

    const [markPermission, setMarkPermission] = useState(0)

  return (
    <div className = "container">
      <div className = "container-map">
        <WrappedMap 
            history = {history}
            setMarkPermission = {setMarkPermission}
            markPermission = {markPermission}
            googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&
            libraries=geometry,drawing,places&key=${
              process.env.REACT_APP_API_KEY_GOOGLE_MAPS
            }`} 
            loadingElement = {<div  style={{height: "100%"}} />}
            containerElement = {<div style={{height: "100%"}} />}
            mapElement = {<div style={{height: "100%"}} />}
          />
      </div>

      <Router history={history}>
        <Route exact path="/" component={GreetingWindow} />
        <Route path="/marker" component={MarkerWindow} />
        <Route path="/waiting" component={WaitWindow} />
        <Route path="/pay" component={PayWindow} />
        <Route path="/form" component={Form} />
        <Route path="/confirm" component={ConfirmWindow} />
        <Route path="/cancel" component={CancelWindow} />
      </Router>
    </div>
  )
}

export default App