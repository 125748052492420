import Axios from "../../utils/http"

const addToken = (tel, history) => dispatch  => {
    Axios.post("/movable/web/identify", {"phoneNumber": tel.toString()})
        .then(result => {
            localStorage.setItem("jwt", result.data.token)
            history.push("/waiting")
            return Axios.get("/movable/web/call", {headers: {"authorization": result.data.token}})
        })
        .then(result => {
            dispatch({type: "ADD_STATUS", payload: result.data.active})
        })
        .catch(err => {
            dispatch({type: "ADD_STATUS", payload: -2})
            console.log(err)
        })
        .catch(err => console.log(err))
}

export default addToken