const initialState = {
    evacCoords: [],
    tel: ""
}

const evacuator = (state = initialState, action) => {

    if(action.type === "ADD_EVACUATOR") {
        return {...state, evacCoords: action.payload.object.gps.split(","), tel: action.payload.administrator.phone}
    }

    else {
        return {...state}
    }

}

export default evacuator