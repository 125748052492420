import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import Recaptcha from "react-recaptcha"
import {connect} from "react-redux"
import {addToken} from "../../redux/actions"
import "./GreetingWindow.sass"
import Axios from "../../utils/http"

const GreetingWindow = ({history, myCoords, addToken}) => {

    const [tel, setTel] = useState("")
    const [chckNum, setChckNum] = useState(0)
    const [countries, setCountries] = useState(0)
    const [captcha, setCaptcha] = useState(0)
    const [region, setRegion] = useState("+375")

    useEffect(() => {
            localStorage.removeItem("callId")
            Axios.get("/phonenumbermasks").then(result => {setCountries(result.data)})
        },[])

    const handleToken = () => { 
        if (countries && region && captcha && tel.length === 9) {
            addToken(region+tel, history)
        } else if(tel.length < 9){
            setChckNum(1)
            console.log(chckNum, countries, region, captcha)
        } else {
            console.log(chckNum, countries, region, captcha)
        }
    }

    return (
        <>
        {myCoords.isReady &&
            <div className={"greeting-window-container"}>
                <div className="greeting-window-container-element">
                    <div className="greeting-window-container-element-items">
                        <span className="greeting-window-container-element-items-text">Для вызова эвакуатора сначала введите номер телефона</span>
                    </div>

                    <div className="greeting-window-container-element-items">
                        <div className="greeting-window-container-element-items-tel">
                            <div style={{display: "flex"}}>
                                <select 
                                    onChange={e => {
                                        setRegion(e.target.value)
                                        }}>
                                        {countries && countries.map((value, key) => 
                                        <option key={key} value={value.label}>{value.label}</option>)}
                                </select>

                                <input 
                                    type="tel"
                                    maxLength={region === "+375" ? "9" : "15"}
                                    pattern={region === "+375" ? "/^[0-9]{9}$/" : "/^[0-9]{15}$/"}
                                    placeholder={countries && countries.filter(value => value.label === region)[0].placeholder}
                                    onBlur = {() => setChckNum(1)}
                                    onChange={e =>{
                                        setTel(e.target.value)
                                        }
                                    }
                                    required
                                />
                            </div>
                            <span className={(!(region === "+375" ? tel.length === 9 : tel.length > 2) && Boolean(chckNum)) ? "error-span" : "no-error"}>
                                Проверьте корректность введенного номера телефона!
                            </span>
                        </div>
                    </div>

                    <Recaptcha
                        sitekey={`${process.env.REACT_APP_API_KEY_RECAPTCHA}`}
                        render="explicit"
                        onloadCallback={() => {}}
                        expiredCallback={() => {}}
                        verifyCallback={() => setCaptcha(1)} />

                    <div className="greeting-window-container-element-items">
                        <div className="greeting-window-container-element-items-button" onClick={handleToken}>
                            ОК
                        </div>
                    </div>

                </div>
            </div>
        }
        </>
    )
}


GreetingWindow.propTypes = {
    history: PropTypes.object,
    myCoords: PropTypes.object,
    addToken: PropTypes.func
}

const mapStateToProps = state => ({
    myCoords: state.location.myCoords
})

export default connect(mapStateToProps, {addToken})(GreetingWindow)