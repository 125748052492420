import React, {useState} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {addBilling} from "../../utils/webAPI"
import {addStatus} from "../../redux/actions"

import "./PayWindow.sass"


const PayWindow = ({history, status, price, phone, addStatus}) => {

    const finishPay = () => {
        history.push("/")
    }

    const finishCall = () => {
        localStorage.removeItem("callId")
        addBilling(history)
    }

    const checkStatus = setInterval(addStatus, 5000)

    setInterval(() => {
        console.log("Checking cancel")
        localStorage.getItem("status") === -2 && finishPay()
    }, 10000)
    
    const [moving, setMoving] = useState(0)


    return (
        <>
            <div className={!moving ? "pay-window-container": "pay-window-container-no"}>
                <div className={(status === 4 || status === 3 || status === 2) ? 
                        "pay-window-container-element-4" : (status < 1 && status !== -2) ? 
                            "pay-window-container-element-2" : 
                                status === -2 ? "pay-window-container-element-5" : "pay-window-container-element"}>
                    {status < 1 && status !== -2 && <div className="pay-window-container-element-click-wait-1"></div>}
                    {
                        (
                            status === -2
                                &&
                            !clearInterval(checkStatus)
                                &&
                            <>
                                <h1 style={{marginTop: "100px"}}>Оплата заказа</h1>
                                <div className="pay-window-container-element-items">
                                    <span className="pay-window-container-element-items-text">Услуга эвакуации оказана успешно</span><br /> 
                                    <span className="pay-window-container-element-items-text-description">
                                        Эвакуатор: 
                                    </span><br />  
                                    <span className="pay-window-container-element-items-text-description">
                                        Итого к оплате: {price} BYN
                                    </span><br /> 
                                    <span className="pay-window-container-element-items-text-description">
                                        Оплата возможна только наличными 
                                    </span> 
                                    <br /> 
                                    <br />
                                    <div className="pay-window-container-element-click-button-1" onClick={finishPay}>Ок, завершить</div>
                                </div>  
                            </>                                      
                        )
                            ||
                        (
                            status === 1 
                                &&
                            <>
                                <h1>Ожидание погрузки</h1>
                                    <div className="pay-window-container-element-items">
                                        <span className="pay-window-container-element-items-text">Ваш заказ взят в работу, эвакуатор выехал за вами</span><br />
                                        {
                                            phone 
                                                && 
                                            <>
                                                <span className="pay-window-container-element-items-text-description">
                                                    Номер вашего водителя: <b>{phone}</b>
                                                </span><br />
                                            </>
                                        }
                                        <span className="pay-window-container-element-items-text-description">
                                            Ожидайте дальнейших действий и оставайтесь на странице
                                        </span><br />
                                        <span className="pay-window-container-element-items-text-description">Ожидаем...</span><br />
                                        <div className="pay-window-container-element-click-wait-1"></div><br />
                                        <div className="pay-window-container-element-click-button-2" onClick={() => setMoving(1)}>Посмотреть движение эвакуатора</div>
                                    </div>
                            </>
                        )
                            ||
                        (
                            status === 2 
                                &&
                            <>
                                <h1 style={{marginTop: "100px"}}>Погрузка завершена</h1>
                                <div className="pay-window-container-element-items">
                                    <span className="pay-window-container-element-items-text">Ваш автомобиль погружен на эвакуатор</span><br /> 
                                    <span className="pay-window-container-element-items-text-description">
                                        Ожидайте дальнейших действий и оставайтесь на странице
                                    </span><br />
                                    <span className="pay-window-container-element-items-text-description">Ожидаем...</span><br />
                                    <div className="pay-window-container-element-click-wait-1"></div>
                                </div>   
                            </>          
                        )
                            ||
                        (
                            status === 3 
                                &&
                            <>
                                <h1 style={{marginTop: "100px"}}>Выгрузка завершена</h1>
                                <div className="pay-window-container-element-items">
                                    <span className="pay-window-container-element-items-text">Ваш автомобиль был выгружен</span><br /> 
                                    <span className="pay-window-container-element-items-text-description">Теперь вы можете оплатить заказ</span><br />
                                    <span className="pay-window-container-element-items-text-description">Сумма к оплате {price}</span><br />
                                    <div className="pay-window-container-element-click-button-1" onClick={finishCall}>Ок, перейти к оплате</div>
                                </div>  
                            </>          
                        )
                            ||
                        (
                            status === 4
                                &&         
                            <div className="pay-window-container-element-click">
                                <form action="https://test.paysec.by/pay/order.cfm" method="POST">
                                    <input type="hidden" name="Merchant_ID" value="478558" />
                                    <input type="hidden" name="OrderNumber" value={localStorage.getItem("orderNumber")} />
                                    <input type="hidden" name="OrderAmount" value={price}/>
                                    <input type="hidden" name="OrderCurrency" value="BYN" />
                                    <input type="submit" name="Submit" value="Оплатить" className="pay-window-container-element-click-button-1" />
                                </form>
                            </div>        
                        )
                    }
                </div>
            </div>

            {moving ===1 && <div className="pay-window-container-back" onClick={() => setMoving(0)}>Открыть окно ожидания</div>} 
        </>            
    )
} 

PayWindow.propTypes = {
    history: PropTypes.object,
    status: PropTypes.number,
    price: PropTypes.number,
    phone: PropTypes.string,
    addStatus: PropTypes.func
}

const mapStateToProps = state => ({
    status: state.status.active,
    price: state.price.value,
    phone: state.evacuator.tel
})

export default connect(mapStateToProps, {addStatus})(PayWindow)