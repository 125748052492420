const category = (state = [], action) => {

    if(action.type === "ADD_CATEGORY" && state.filter(category => category === "32").length <= 11) {
        state.push(action.payload)
        return [...state]
    }

    else if(action.type === "DELETE_CATEGORY" && state.indexOf(action.payload) >= 0) {
        state.splice(state.indexOf(action.payload), 1)
        return [...state]
    }

    else {
        return [...state]
    }

}

export default category