const initialState = {
    
    myCoords: {
        isReady: false,
        lat: 0,
        lng: 0
    },

    coords: {
        lat: 0,
        lng: 0
    },

    permision: 0

}

const location = (state = initialState, action) => {
    
    if(action.type === "ADD_MYCOORDS") {
        return {...state, myCoords: action.payload}
    }

    else if(action.type === "ADD_COORDS") {
        return {...state, coords: action.payload, permision: 0}
    }

    else if(action.type === "ADD_MARK") {
        return {...state, permision: 1}
    }

    else {
        return {...state}
    }

}

export default location