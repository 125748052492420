import {combineReducers} from "redux"
import status from "./status"
import location from "./location"
import form from "./form"
import category from "./category"
import price from "./price"
import evacuator from "./evacuator"

export default combineReducers({
    status,
    location,
    form,
    category, 
    price,
    evacuator
})