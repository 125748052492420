import Axios from "../../utils/http"

const addPrice = (jwt, gps, carNumber, car, categoryId, address) => dispatch => {

    let categoriesId = []

    if(car === undefined) {
        categoriesId = categoryId.concat("22")   
    } else {
        categoriesId = categoryId.concat(car)
    }

    console.log(categoryId)

    Axios.post("/movable/web/call/calc",
            {
                "gps": [ gps.lat.toString(), gps.lng.toString()],        
                "carNumber": carNumber,
                "categoriesGoods": categoriesId.toString(),
                "address": address
            }, 

            { 
                headers: {
                    "content-Type": "application/json",
                    "authorization": jwt
                }
            }
        ).then(result => {
            dispatch({type: "ADD_PRICE", payload: result.data.calculatedPrice})
            localStorage.setItem("price", result.data.calculatedPrice)})
}

export default addPrice