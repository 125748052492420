import Axios from "../../utils/http"

const addStatus = () => dispatch => {
    Axios.get("/movable/web/call", {headers: {"authorization": localStorage.getItem("jwt")}})
        .then(result => {
                dispatch({type: "ADD_STATUS", payload: result.data.active})
                localStorage.setItem("status", result.data.active)
                result.data.active === 1 && dispatch({type: "ADD_EVACUATOR", payload: result.data})
                result.data.active === 4 && dispatch({type: "ADD_PRICE", payload: result.data.billing.amountForPayment})
                console.log(result.data.active)
                console.log(result)
        })
        .catch(err => {
            dispatch({type: "ADD_STATUS", payload: -2})
            localStorage.setItem("status", -2)
            console.log(err)
        })
}   

export default addStatus