import React from "react"
import {connect} from "react-redux"
import {addMark} from "../../redux/actions"
import PropTypes from "prop-types"

import "./MarkerWindow.sass"

const MarkerWindow = ({history, mark, addMark}) => {

    const changeLocation = () => {
        addMark()
        history.push("/form")
    }

    return (
        <div className={!mark ? "marker-window-container" : "marker-window-container-no"}>
            <div className="marker-window-container-element">
                <div className="marker-window-container-element-items">
                    <span className="marker-window-container-element-items-text">Необходимо определить вашу геолокацию</span><br /><br /><br />
                    <span className="marker-window-container-element-items-text-description">На карте уже указана и сохранена ваша геолокация.</span> 
                    <span className="marker-window-container-element-items-text-description">
                        Если вы хотите вызвать эвакуатор по своему месторасположению нажмите на «Продолжить».
                    </span>
                    <span className="marker-window-container-element-items-text-description">Если хотите выбрать другое место нажмите на «Изменить».</span>
                </div>
                <div className="marker-window-container-element-click">
                    <div className="marker-window-container-element-click-button-1" onClick={() => history.push("/form")}>Продолжить</div>                     
                    <div className="marker-window-container-element-click-button-2" onClick={() => changeLocation()}>Изменить</div> 
                </div>
            </div>            
        </div>
    )
}

MarkerWindow.propTypes = {
    history: PropTypes.object,
    mark: PropTypes.number,
    addMark: PropTypes.func
}

const mapStateToProps = state => ({
    mark: state.location.permision
})

export default connect(mapStateToProps, {addMark})(MarkerWindow)