import Axios from "../../utils/http"

export const addCall = (jwt, gps, carNumber, categoryId, address) => {
    Axios.post("/movable//web/call",
        {
            "gps": [ gps.lat.toString(), gps.lng.toString()],
            "carNumber": carNumber,
            "categoriesGoods": categoryId.toString(),
            "address": address
        }, 
        { 
            headers: {
                "content-Type": "application/json",
                "authorization": jwt
            }
        }
    ).then(result => localStorage.setItem("callId", result.data.id))
}

export const addBilling = history => {
    console.log("Billing")
    Axios.post("/movable/web/call/pay", null, {headers: {"authorization": localStorage.getItem("jwt")}})
        .then(result => {
            console.log(result)
            localStorage.setItem("orderNumber", result.data.billing.btuid)
        })
        .catch(err => {
            console.log(err)
            history.push("/")
        })
}

export const deleteCall = () => {
    Axios.delete(`/movable/web/call/${localStorage.getItem("callId")}`, {headers: {"authorization": localStorage.getItem("jwt")}})
        .then(result => {
            console.log(result)
            localStorage.removeItem("callId")
            localStorage.removeItem("status")
        })
}