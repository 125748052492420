const intialState = {
    value: 0
}

const price = (state = intialState, action) => {
    
    if (action.type === "ADD_PRICE") {
        return {...state, value: action.payload}
    }

    else {
        return {...state}
    }

}

export default price