import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {addStatus} from "../../redux//actions"
import "./WaitWindow.sass"

const WaitWindow = ({history, status, addStatus}) => {

    useEffect(() => {
        addStatus()
    },[addStatus])

    return(
        <div className="wait-window-container">
            <div className="wait-window-container-element">
                {(status === -2 || status === -1 || status === 5) && history.push("/marker")}
                {status >= 0 && history.push("/confirm")}
            </div>
        </div>
    )
} 

WaitWindow.propTypes = {
    history: PropTypes.object,
    status: PropTypes.number,
    addStatus: PropTypes.func
}

const mapStateToProps = state => ({
    status: state.status.active
})

export default connect(mapStateToProps, {addStatus})(WaitWindow)