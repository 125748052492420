import React from "react"
import PropTypes from "prop-types"

import "./CancelWindow.sass"

const CancelWindow = ({history}) => {


    return (
        <div className="cancel-window-container">
            <div className="cancel-window-container-element">
                <h1>Отмена вызова</h1>
                <h2>Вы отменили заказ, который находлися в обработке</h2>
                <h3>Стоимость отмененного вызова: {localStorage.getItem("price")} BYN</h3>
                <div className="cancel-window-container-element-items-button" 
                     onClick={() => {
                        localStorage.removeItem("callId")
                        localStorage.removeItem("price")
                        localStorage.removeItem("status")
                        history.push("/form")
                        }}>
                    Вернуться и создать новый заказ
                </div>
                <br />
                <div className="cancel-window-container-element-items-button" 
                     onClick={() => {
                        localStorage.removeItem("callId")
                        localStorage.removeItem("price")
                        localStorage.removeItem("status")
                        history.push("/")
                         }}>
                    Завершить
                </div>
                <br />
            </div>
        </div> 
    )
}

CancelWindow.propTypes = {
    history: PropTypes.object
}

export default CancelWindow