const initialState = {
    address: "",
    auto: [],
    carType: "", 
    carModel: "",
    carNumber: "",
    comment: ""
}


const form = (state = initialState, action) => {
    
    if (action.type === "ADD_ADDRESS") {
        return {...state, address: action.payload}
    }

    else if (action.type === "ADD_AUTO") {
        return {...state, auto: action.payload}
    }
    
    else if (action.type === "ADD_CARTYPE") {
        return {...state, carType: action.payload}
    }

    else if (action.type === "ADD_CARMODEL") {
        return {...state, carModel: action.payload}
    }
    
    else if (action.type === "ADD_CARNUMBER") {
        return {...state, carNumber: action.payload}
    }

    else if (action.type === "ADD_COMMENT") {
        return {...state, comment: action.payload}
    }

    else {
        return {...state}
    }

}

export default form