import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {GoogleMap, withScriptjs, withGoogleMap, Marker} from "react-google-maps"
import {connect} from "react-redux"
import {addCoords, addMyCoords} from "../../redux/actions"

const Map = ({mark, myCoords, coords, status, evacCoords, addCoords, addMyCoords}) => {


  useEffect(() => {
    navigator.geolocation.watchPosition(position => {
      addMyCoords({
        isReady: true,
        lat: position.coords.latitude, 
        lng: position.coords.longitude
      })
    })
  },[addMyCoords])

  const chahgeCoords = e => {
    addCoords({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    })
  }

  const isMarksSet = () => coords.lat && coords.lng

  return (
    <> 
    {myCoords.isReady &&
      <GoogleMap 
        defaultZoom = {12} 
        defaultCenter = {{lat: myCoords.lat, lng: myCoords.lng}} 
        onClick = {e => {
          mark && chahgeCoords(e)
          }}
      >
           
          {!isMarksSet() ? 
            <>
              <Marker position = {{lat: myCoords.lat, lng: myCoords.lng}} /> 
            </> :
            <>
              <Marker position = {{lat: coords.lat, lng: coords.lng}} />
            </>
          }
          {status === 1 && <Marker position = {{lat: Number(evacCoords[0]), lng: Number(evacCoords[1])}} /> }

      </GoogleMap>
    }
    </>
  )
}

Map.propTypes = {
  history: PropTypes.object,
  mark: PropTypes.number,
  myCoords: PropTypes.object,
  coords: PropTypes.object,
  status: PropTypes.number,
  evacCoords: PropTypes.array,
  addCoords: PropTypes.func,
  addMyCoords: PropTypes.func,
  setMarkPermission: PropTypes.func,
  markPermission: PropTypes.number
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

const mapStateToProps = state => ({
  mark: state.location.permision,
  myCoords: state.location.myCoords,
  status: state.status.active,
  evacCoords: state.evacuator.evacCoords,
  coords: state.location.coords
})

export default connect(mapStateToProps, {addMyCoords, addCoords})(WrappedMap)