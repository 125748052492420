import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import PropTypes from "prop-types"
import Info from "../../assets/images/Info.svg"
import {addMark, addAddress, addAuto, addCarType, addCarModel, addCarNumber, addComment, addCategory, addPrice, deleteCategory} from "../../redux/actions"
import {addCall} from "../../utils/webAPI"
import Axios from "../../utils/http"
import "./Form.sass"

import Geocode from "react-geocode"

const Form = props => {

    const {history} = props
    const {mark, myCoords, coords, address, auto, carType, carModel, carNumber, comment, categoryId, price} = props
    const {addMark, addAddress, addAuto, addCarType, addCarModel, addCarNumber, addComment, addCategory, deleteCategory, addPrice} = props 
 
    const [chckcom, setCheckRequire] = useState(0)

    useEffect(() => {
        !auto.length && Axios.get("/categories")
            .then(result => addAuto(result.data))  

        Geocode.setApiKey(`${process.env.REACT_APP_API_KEY_GOOGLE_MAPS}`) 
        Geocode.setLanguage("ru")
        Geocode.setRegion("by")
        Geocode.enableDebug()
        const position = (Boolean(coords.lat) || Boolean(coords.lng)) ? coords : myCoords
        Geocode.fromLatLng(position.lat, position.lng).then(
            response => {
                addAddress(response.results[0].formatted_address)
            },
            error => {
                console.error(error)
            }
        )

    },[addAddress, auto, addAuto, coords, myCoords])

    const changeCategory = id => {
        categoryId.indexOf(id) === -1 ? addCategory(id) : deleteCategory(id)
    }    
        
    const calcPrice = () => {
        if(Boolean(carNumber) && Boolean(comment)){
            const gps = (coords.lng && coords.lat) ? coords : myCoords
            const car = carType.split("; ")[1]
            if(carNumber && comment && !localStorage.getItem("callId")) {
                try {
                    addPrice(localStorage.getItem("jwt"), gps, carNumber, car, categoryId, address)
                } catch (err) {
                    console.log(err)
                }
            }
    
            else if(localStorage.getItem("callId")) {
                history.push("/confirm")
            }
        }
        else {
            setCheckRequire(1)
        }
    } 

    const confirmPrice = () => {
        const gps = (coords.lng && coords.lat) ? coords : myCoords
        addCall(localStorage.getItem("jwt"), gps, carNumber, categoryId, address)
        history.push("/confirm")
    }

    return (
        <div className={!mark ? "form-window-container" : "form-window-container-no"}>
            <div className="form-window-container-element">
                <div className="form-window-container-element-items">
                    <br /><br />
                    <span className="form-window-container-element-items-text">Эвакуация авто</span>
                </div>
                
                <div className="form-window-container-element-fields">
                    <div className="form-window-container-element-fields-col col-1">
                        <div className="form-window-container-element-fields-col-input-1">
                            <br />
                            <span className="form-window-container-element-fields-col-input-1-title-1">Ваше местоположение</span>
                            <div className="form-window-container-element-fields-col-input-1-address">{address}</div>
                        </div>
                      
                        <div className="form-window-container-element-fields-col-input-1">
                            <span className="form-window-container-element-fields-col-input-1-title-1">Тип автмобиля</span>
                            <select className="form-window-container-element-fields-col-input-1-select"
                             onChange={e => {
                                addCarType(e.target.value + "; " + e.target.childNodes[e.target.selectedIndex].attributes[0].value)
                                }}>
                                {auto && auto.filter(element => element.key.includes("carTypes.")).map((value, key) => 
                                    carType === value.label ? 
                                        <option key={key} name={value.id} value={value.label} selected>{value.label}</option> :
                                        <option key={key} name={value.id} value={value.label}>{value.label}</option>
                                )}
                            </select>
                        </div>

                        <div className="form-window-container-element-fields-col-input-1">
                            <span className="form-window-container-element-fields-col-input-1-title-1">Марка автмобиля</span>
                            <select className="form-window-container-element-fields-col-input-1-select" onChange={e => addCarModel(e.target.value)}>
                                {auto && auto.filter(element => element.key.includes("carBrand.")).map((value, key) => 
                                    carModel === value.label ? 
                                        <option key={key} value={value.label} selected>{value.label}</option> :
                                        <option key={key} value={value.label}>{value.label}</option>
                                    )}
                            </select>
                        </div>

                        <div className="form-window-container-element-fields-col-input-1">
                            <span className="form-window-container-element-fields-col-input-1-title-1">Номер автомобиля</span>
                            <div className="form-window-container-element-fields-col-input-1-text">
                                <input 
                                    type="text" 
                                    maxLength="20" 
                                    value={carNumber} 
                                    onChange={e => addCarNumber(e.target.value.toUpperCase())} 
                                    onBlur={() => {setCheckRequire(1)}} 
                                    required /> 
                                    <span className={(!carNumber  && Boolean(chckcom)) ? "error-span" : "no-error"}>
                                        Номер автомобиля должен быть обязательно указан!
                                    </span>
                            </div>
                        </div>
                        <br />
                        <div className="form-window-container-element-fields-col-input-1">
                            <span className="form-window-container-element-fields-col-input-1-title-1">Комментарий к заказу</span>
                            <div className="form-window-container-element-fields-col-input-1-text">
                                <input 
                                    type="text" 
                                    maxLength="200" 
                                    value={comment} 
                                    onChange={e => {addComment(e.target.value)}} 
                                    onBlur={() => {setCheckRequire(1)}} 
                                    required />
                                <span className={(!comment  && Boolean(chckcom)) ? "error-span" : "no-error"}>
                                    Комментарий должен быть обязательно указан!
                                </span>
                            </div>
                            <div style={{height: "1vh", display: "flex", justifyContent: "flex-end"}}>
                                {comment.length || 0}/200
                            </div>
                        </div>
                        <br />

                        <div className="info-1">
                            <div className="info-1-module">
                                <img src={Info} alt="Info" />
                                <span className="info-1-alt">
                                    Для осуществления перевозки Вам необходимо предоставить Исполнителю свидетельство о регистрации автомобиля
                                </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="form-window-container-element-fields-col col-2">            
                        <span className="form-window-container-element-fields-col-input-1-title-2">Состояние автомобиля</span>            
                        <div className="form-window-container-element-fields-col-input-2">
                            <div className="form-window-container-element-fields-col-input-2-field">
    
                                {categoryId.indexOf("28") === -1 ?
                                    <input id="gear" type="checkbox" onChange={() => changeCategory("28")} /> :
                                    <input id="gear" type="checkbox" onChange={() => changeCategory("28")} checked />}

                                <label htmlFor="gear" className="form-window-container-element-fields-col-input-2-field-check">
                                    Заблокирована коробка передач / не снимается с ручника
                                </label>
                            </div>
                        </div>
                        <div className="form-window-container-element-fields-col-input-2">
                            <div className="form-window-container-element-fields-col-input-2-field">
                               
                                {categoryId.indexOf("30") === -1 ? 
                                    <input id="stance" type="checkbox" onChange={() => changeCategory("30")} /> :
                                    <input id="stance" type="checkbox" onChange={() => changeCategory("30")} checked />}
                               
                                <label htmlFor="stance" className="form-window-container-element-fields-col-input-2-field-check">Низкая посадка</label>
                            </div>
                        </div>
                        
                        <div className="form-window-container-element-fields-col-input-2">
                            <div className="form-window-container-element-fields-col-input-2-field">
                                
                                {categoryId.indexOf("31") === -1 ?
                                    <input id="hook" type="checkbox" onChange={() => changeCategory("31")}  /> :
                                    <input id="hook" type="checkbox" onChange={() => changeCategory("31")} checked />}
                                
                                <label htmlFor="hook" style={{width: "70%"}} className="form-window-container-element-fields-col-input-2-field-check">
                                    Нет / повреждён буксировочный крюк
                                </label>
                            </div>
                        </div>
                        
                        <div className="form-window-container-element-fields-col-input-2">
                            <div className="form-window-container-element-fields-col-input-2-field">
                                
                                {categoryId.indexOf("33") === -1 ?
                                    <input id="ditch" type="checkbox" onChange={() => changeCategory("33")} /> :
                                    <input id="ditch" type="checkbox" onChange={() => changeCategory("33")} checked />}
                                
                                <label htmlFor="ditch" className="form-window-container-element-fields-col-input-2-field-check">Авто в кювете</label>
                            </div>
                        </div>
                        
                        <div className="form-window-container-element-fields-col-input-2">
                            <div className="form-window-container-element-fields-col-input-2-field">

                                {categoryId.indexOf("34") === -1 ?
                                    <input id="place" type="checkbox" onChange={() => changeCategory("34")} /> :
                                    <input id="place" type="checkbox" onChange={() => changeCategory("34")} checked />}

                                <label htmlFor="place" className="form-window-container-element-fields-col-input-2-field-check">Авто в труднодоступном месте</label>
                            </div></div>                       
                        
                        <div className="form-window-container-element-fields-col-input-2">
                            <div className="form-window-container-element-fields-col-input-2-field">

                                {categoryId.indexOf("35") === -1 ?
                                    <input id="manipulate" type="checkbox" onChange={() => changeCategory("35")} /> :
                                    <input id="manipulate" type="checkbox" onChange={() => changeCategory("35")} checked />}

                                <label htmlFor="manipulate" className="form-window-container-element-fields-col-input-2-field-check">Только погрузка манипулятором</label>
                            </div>
                        </div>
                        
                        <div className="form-window-container-element-fields-col-click-2">
                            <div className="form-window-container-element-fields-col-click-2-field" style={{borderBottom: "0px"}}>
                                <div className="form-window-container-element-fields-col-click-2-field-amount">
                                    <div className="form-window-container-element-fields-col-click-2-field-amount-operation"
                                        onClick={() => addCategory("32")}>+</div>
                                    
                                    <div className="form-window-container-element-fields-col-click-2-field-amount-value">
                                        {categoryId.filter(id => id === "32").length}
                                    </div>
                                    
                                    <div className="form-window-container-element-fields-col-click-2-field-amount-operation"
                                        onClick={() => deleteCategory("32")}>-</div>
                                </div>
                                <label htmlFor="wheel" className="form-window-container-element-fields-col-click-2-field-check">Повреждено / заблокировано колесо</label>
                            </div>
                        </div> 
                        <br />   

                        <div className="info-2">
                            <br />
                            <div className="info-2-module">
                                <img src={Info} alt="Info" />
                                <span className="info-2-alt">
                                    Для осуществления перевозки Вам необходимо предоставить Исполнителю свидетельство о регистрации автомобиля
                                </span>
                            </div>
                        </div>
                        <br />  
                        <br />  
                        <br />   

                        <div className="form-window-container-element-fields-col-click-2">
                            <div className="form-window-container-element-fields-col-click-2-details">
                                <div className="form-window-container-element-fields-col-click-2-sum" >
                                    <span className="form-window-container-element-fields-col-click-2-text">Стоимость</span>
                                    <span className="form-window-container-element-fields-col-click-2-text" style={{color: "#483D3F"}}>
                                        {price} BYN
                                    </span>
                                </div>
                                <div className="form-window-container-element-items-button" onClick={calcPrice}>
                                    {price ? <span>Пересчитать стоимость</span> : <span>Посчитать стоимость</span>}
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />

                       {Boolean(price) && 
                        <div className="form-window-container-element-fields-col-click-2">
                            <div className="form-window-container-element-fields-col-click-2-details">
                                <div className="form-window-container-element-fields-col-click-2-ready">
                                    <span className="form-window-container-element-fields-col-click-2-text">Подтвердить заказ?</span>
                                </div>
                                <div className="form-window-container-element-items-button" onClick={confirmPrice}>
                                    Подтвердить
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        
            <div className="form-window-container-back" onClick={addMark}>
                Изменить расположение
            </div>
        </div>
    )
}

Form.propTypes = {
    myCoords: PropTypes.object,
    coords: PropTypes.object,
    mark: PropTypes.number,
    address: PropTypes.string,
    auto: PropTypes.array,
    carType: PropTypes.string,
    carModel: PropTypes.string,
    carNumber: PropTypes.string,
    comment: PropTypes.string,
    categoryId: PropTypes.array,
    price: PropTypes.number,
    history: PropTypes.object,
    addMark: PropTypes.func,
    addAddress: PropTypes.func,
    addAuto: PropTypes.func,
    addCarType: PropTypes.func,
    addCarModel: PropTypes.func,
    addCarNumber: PropTypes.func,
    addComment: PropTypes.func,
    addCategory: PropTypes.func,
    addPrice: PropTypes.func,
    addCall: PropTypes.func,
    deleteCategory: PropTypes.func
}

const mapStateToProps = state => ({
    myCoords: state.location.myCoords,
    coords: state.location.coords,
    mark: state.location.permision,
    address: state.form.address,
    auto: state.form.auto,
    carType: state.form.carType, 
    carModel: state.form.carModel,
    carNumber: state.form.carNumber,
    comment: state.form.comment,
    categoryId: state.category,
    price: state.price.value
})

export default connect(mapStateToProps, {addAddress, addAuto, addCarType, addCarModel, addCarNumber, addComment, addMark, addCategory, addPrice, deleteCategory})(Form)