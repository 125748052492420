const initialState = {
    active: -10
}

const status = (state = initialState, action) => {

    if(action.type === "ADD_STATUS") {
        return {state, active: action.payload}
    }

    else {
        return {...state}
    }

}

export default status