import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {addStatus} from "../../redux/actions"
import {deleteCall} from "../../utils/webAPI/"

import "./ConfirmWindow.sass"

const confirmWindow = ({history, status, addStatus}) => {

    const checkStatus = setInterval(addStatus, 3000)
    
    setInterval(() => {
        // console.log("Checking cancel")
        localStorage.getItem("status") === -2 && history.push("/cancel")
    }, 10000)


    const cancelCall = () => {
        deleteCall()
        history.push("/cancel")
        clearInterval(checkStatus)
    }

    return (
        <div className="confirm-window-container">
            <div className="confirm-window-container-element">
                <h1>Поиск свободных эвакуаторов</h1>
                <div className="confirm-window-container-element-items">
                    <span className="confirm-window-container-element-items-text">У вас есть активный заказ</span><br />
                    {status >= 1 ? 
                        <>
                            <span className="confirm-window-container-element-items-text-description">Чтобы продолжить нажмите ОК</span>                        
                        </> : 
                        <>
                            <span className="confirm-window-container-element-items-text-description">В данный момент идет поиск свободных эвакуаторов</span> 
                            <span className="confirm-window-container-element-items-text-description">Не покидайте страницу</span>
                        </>
                    }
                </div>
                <div className="confirm-window-container-element-click">
                    {
                        status >= 1
                            ?
                        (
                            !clearInterval(checkStatus)
                                &&
                            <div className="confirm-window-container-element-click-button-1" onClick={() => {history.push("/pay")}}>ОК</div>
                        )
                            :
                        <div className="confirm-window-container-element-click-wait-1"></div>
                    }      
                    <br />               
                    {
                        status <= 1 
                            && 
                        <div className="confirm-window-container-element-click-button-2" onClick={cancelCall}>Отменить вызов</div>
                    }
                </div>
            </div>            
        </div>
    )
}

confirmWindow.propTypes = {
    history: PropTypes.object,
    status: PropTypes.number,
    addStatus: PropTypes.func
}

const mapStateToProps = state => ({
    status: state.status.active
})


export default connect(mapStateToProps, {addStatus})(confirmWindow)